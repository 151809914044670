import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  topContent: {
    position: 'relative',
    zIndex: 100,
    marginTop: '-3rem',
  },
  content: {
    paddingTop: '2rem',
    marginBottom: '-15px',
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
    },
  },
  header: {
    padding: '1rem 0',
  },
  subHeader: {
    padding: '1rem 0',
    fontWeight: 500,
  },
  contentHeader: {
    padding: '1rem 0',
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  bold: {
    fontWeight: 700,
  },
  paragraph: {
    lineHeight: 1.4,
    marginBottom: '1rem',
  },
  linkText: {
    color: theme.mediumDarkGray,
  },
}));

export const SpecialUpdateBody = ({
  upperSectionContent,
  featuredImage,
  lowerSectionContent,
}) => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          className={classes.topContent}
        >
          <Grid item xs={12} lg={10}>
            <PortableText
              content={upperSectionContent || []}
              serializers={{
                h2: ({ children }) => (
                  <Typography
                    variant='h2'
                    color='primary'
                    className={classes.header}
                  >
                    {children}
                  </Typography>
                ),
                h3: ({ children }) => (
                  <Typography
                    variant='h3'
                    color='primary'
                    className={classes.subHeader}
                  >
                    {children}
                  </Typography>
                ),
                h4: ({ children }) => (
                  <Typography
                    variant='h4'
                    color='primary'
                    style={{ padding: '1rem 0' }}
                  >
                    {children}
                  </Typography>
                ),
                h5: ({ children }) => (
                  <Typography
                    variant='h5'
                    color='primary'
                    className={classes.contentHeader}
                  >
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <span className={classes.bold}>{children}</span>
                ),
                normal: ({ children }) => (
                  <Typography variant='body1' className={classes.paragraph}>
                    {children}
                  </Typography>
                ),
                li: ({ children }) => (
                  <Grid item>
                    <Typography variant='body1'>
                      <li>{children}</li>
                    </Typography>
                  </Grid>
                ),
                emailPhoneLink: (props) => {
                  return (
                    <a href={props.emailPhoneHref} className={classes.linkText}>
                      {props.children[0]}
                    </a>
                  );
                },
                link: (props) => {
                  return (
                    <a
                      className={classes.linkText}
                      href={props.href}
                      style={{ textDecoration: 'underline' }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {props.children[0]}
                    </a>
                  );
                },
                internalLink: ({ children, internalSlug }) => (
                  <Link to={`/${internalSlug}`} className={classes.linkText}>
                    {children}
                  </Link>
                ),
                code: ({ children }) => (
                  <code style={{ color: 'green' }}>
                    {children}
                  </code>
                ),
              }}
            />
          </Grid>
          {featuredImage && (
            <Grid item xs={12} lg={10}>
              <GatsbyImage image={featuredImage.asset.gatsbyImageData} />
            </Grid>
          )}
        </Grid>

        {!!lowerSectionContent ? (
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.content}
          >
            <Grid item xs={12} lg={10}>
              <PortableText
                content={lowerSectionContent || []}
                serializers={{
                  h2: ({ children }) => (
                    <Typography
                      variant='h2'
                      color='primary'
                      className={classes.header}
                    >
                      {children}
                    </Typography>
                  ),
                  h3: ({ children }) => (
                    <Typography
                      variant='h3'
                      color='primary'
                      className={classes.subHeader}
                    >
                      {children}
                    </Typography>
                  ),
                  h4: ({ children }) => (
                    <Typography variant='h4' color='primary'>
                      {children}
                    </Typography>
                  ),
                  h5: ({ children }) => (
                    <Typography
                      variant='h5'
                      color='primary'
                      className={classes.contentHeader}
                    >
                      {children}
                    </Typography>
                  ),
                  strong: ({ children }) => (
                    <span className={classes.bold}>{children}</span>
                  ),
                  normal: ({ children }) => (
                    <Typography variant='body1'>{children}</Typography>
                  ),
                  li: ({ children }) => (
                    <Grid item>
                      <Typography variant='body1'>
                        <li>{children}</li>
                      </Typography>
                    </Grid>
                  ),
                  emailPhoneLink: (props) => {
                    return (
                      <a
                        href={props.emailPhoneHref}
                        className={classes.linkText}
                      >
                        {props.children[0]}
                      </a>
                    );
                  },
                  link: (props) => {
                    return (
                      <a
                        className={classes.linkText}
                        href={props.href}
                        style={{ textDecoration: 'underline' }}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {props.children[0]}
                      </a>
                    );
                  },
                  internalLink: ({ children, internalSlug }) => (
                    <Link to={`/${internalSlug}`} className={classes.linkText}>
                      {children}
                    </Link>
                  ),
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Container>
    </>
  );
};
