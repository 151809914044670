import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SEO } from '../components/SEO';
import { SpecialUpdateBody } from '../components/SpecialUpdate/SpecialUpdateBody';
import Form from '../components/ContactForm/Form';

import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';

import LocaleContext from '../context/LocaleContext';

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.white,
  },
  heroVideo: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
  },

  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '32.95%',
    minHeight: '100%',
    minWidth: '59%',
    border: 'none',
    marginTop: '-12rem',
    zIndex: 10,
    borderRadius: '8px',
    overflow: 'hidden',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('md')]: {
      marginTop: '-10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '45%',
      minHeight: '100%',
      minWidth: '80%',
      marginTop: '-6rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '56.25%',
      minHeight: '100%',
      minWidth: '100%',
      marginTop: '-3rem',
    },
  },
}));

const HeroVideo = ({
  url,
  playing = false,
  loop = false,
  muted = false,
  controls = true,
  width = '100%',
  height = '100%',
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ paddingBottom: '4rem' }}
    >
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={url}
          playing={playing}
          loop={loop}
          muted={muted}
          width={width}
          height={height}
          controls={controls}
          className={classes.reactPlayer}
        />
      </div>
    </Grid>
  );
};

export default function SpecialUpdatePage({
  data: { specialUpdate },
  location,
}) {
  const classes = useStyles();
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    metaTitle,
    metaDescription,
    noindex,
    hero,
    _rawUpperSectionContent,
    featuredImage,
    _rawLowerSectionContent,
    pardotUrl,
    contactForm,
  } = specialUpdate;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  if (!hero) return <div>no hero here</div>;

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} noindex={noindex} />
      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      {hero.heroVideo ? (
        <Container>
          <HeroVideo url={hero.heroVideo} />
        </Container>
      ) : null}

      <SpecialUpdateBody
        upperSectionContent={_rawUpperSectionContent}
        featuredImage={featuredImage}
        lowerSectionContent={_rawLowerSectionContent}
      />
      <Form
        pardotUrl={pardotUrl}
        contactForm={contactForm}
        location={location}
        noForm
      />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    specialUpdate: sanitySpecialUpdate(slug: { current: { eq: $slug } }) {
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      noindex
      hero {
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        softwareLogo {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              fit: FILLMAX
              # width: 350
              height: 90
            )
          }
        }
      }
      _rawUpperSectionContent
      featuredImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      _rawLowerSectionContent
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
    }
  }
`;
